<template>
  <!-- 注册页面 -->
  <v-app>
    <v-main class="align-center background-color">
      <v-container fluid>
        <v-card
          :max-width="$vuetify.breakpoint.xs ? 300 : 500"
          :max-height="$vuetify.breakpoint.smAndDown ? 500 : ''"
          class="mx-auto overflow-y-auto"
          :class="$vuetify.breakpoint.xs ? 'px-6 pb-2' : 'px-16 py-10'"
        >
          <v-card-title class="justify-center register-title">{{$t('lang.register.title')}}</v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-form ref="form">
              <!-- <v-select
                :label="$t('lang.register.customerTypeLabel')"
                outlined
                dense
                :items="customerTypes"
                item-text="text"
                item-value="value"
                return-object
                v-model="selectedCustomerType"
              ></v-select>
              <div v-if="selectedCustomerType.value == 'company'">
                <v-sheet
                  min-height="80"
                  max-width="150"
                  class="grey lighten-4 d-flex align-center justify-center"
                >
                  <v-file-input
                    hide-input
                    accept="image/*"
                    prepend-icon="mdi-plus-circle"
                    class="pt-0 mt-0 justify-center"
                    style="z-index:1"
                  ></v-file-input>
                  <v-img
                    :src="businessLicensePhotoUrl"
                    max-height="65"
                    max-width="65"
                    style="position:absolute;"
                  ></v-img>
                </v-sheet>
                <p class="text-center caption" style="max-width:150px">{{$t('lang.register.businessLicense')}}</p>
              </div> -->
              <v-text-field outlined autofocus dense :label="$t('lang.register.usernameLabel')" v-model="form.username" :rules="nameRules" :counter="20" required></v-text-field>
              <v-text-field outlined dense :label="$t('lang.register.passwordLabel')" v-model="form.password" type="password" :rules="passwordRules" :counter="25" required></v-text-field>
              <v-text-field outlined dense :label="$t('lang.register.confirmPwdLabel')" v-model="form.confirmPwd" type="password" :rules="confirmPwdRules" :counter="25" required></v-text-field>
              <v-text-field outlined dense :label="$t('lang.register.phoneNumberLabel')" v-model="form.phoneNumber" type="number" :rules="phoneNumberRules" required></v-text-field>
              <v-text-field outlined dense :label="$t('lang.register.verificationCodeLabel')" v-model="form.verificationCode" type="number" :rules="verificationCodeRules" required>
                <template v-slot:append-outer>
                  <v-btn small @click="getVerificationCode()" :disabled="verificationCodeBtnAble">{{ verificationCodeBtnAble ? verificationCodeBtnlabel : $t('lang.register.verificationCodeBtn') }}</v-btn>
                </template>
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn class="white--text" color="rgb(0, 110, 255)" @click="register()">{{$t('lang.register.registerBtn')}}</v-btn>
          </v-card-actions>
          <div class="login-tip mt-2">
            <v-btn text small color="rgb(0, 110, 255)" @click="toLogin()">{{$t('lang.register.toLoginBtn')}}</v-btn>
          </div>
        </v-card>
        <div class="copyright white--text" :style="{'width': $i18n.locale == 'en-US' ? '161px' : '112px'}">© 2022 {{$t('lang.register.weiHaiWuZhou')}}</div>
      </v-container>
    </v-main>
    <v-snackbar :timeout="tips.timeout" v-model="tips.showSnackbar" :color="tips.snackbarColor" :top="true">{{ tips.snackbarText }}</v-snackbar>
  </v-app>
</template>
<script>
let vm;
import axios from "axios";
import commonCfg from "../config/common";
let apiUrl = commonCfg.commonUrl
import qs from "qs";
export default {
  data() {
    return {
      // customerTypes: [
      //   { text: this.$t('lang.register.naturalPerson'), value: "naturalPerson" },
      //   { text: this.$t('lang.register.company'), value: "company" },
      // ],
      //selectedCustomerType: 'naturalPerson',
      //businessLicensePhotoUrl: require("../../public/logo.png"),
      form: {
        username: '',
        password: '',
        confirmPwd: '',
        phoneNumber: '',
        verificationCode: ''
      },
      nameRules: [
        v => !!v || this.$t('lang.register.usernameRules.required'),
        v => /^[\u4e00-\u9fa5_$a-zA-Z0-9]+$/.test(v) || this.$t('lang.register.usernameRules.specialCharacterRestriction'),
        v => v.length >= 8 || this.$t('lang.register.usernameRules.minimumCharacterLimit'),
        v => v.length <= 20 || this.$t('lang.register.usernameRules.maximumCharacterLimit'),
      ],
      passwordRules: [
        v => !!v || this.$t('lang.register.passwordRules.required'),
        v => v.length >= 16 || this.$t('lang.register.passwordRules.minimumCharacterLimit'),
        v => v.length <= 25 || this.$t('lang.register.passwordRules.maximumCharacterLimit'),
        v => /^(?=.*[0-9])(?=.*[a-zA-Z])(.{16,25})$/.test(v) || this.$t('lang.register.passwordRules.specialCharacterRestriction'),
      ],
      confirmPwdRules: [
        v => !!v || this.$t('lang.register.confirmPwdRules.required'),
        v => v.length >= 16 || this.$t('lang.register.confirmPwdRules.minimumCharacterLimit'),
        v => v.length <= 25 || this.$t('lang.register.confirmPwdRules.maximumCharacterLimit'),
        v => /^(?=.*[0-9])(?=.*[a-zA-Z])(.{16,25})$/.test(v) || this.$t('lang.register.confirmPwdRules.specialCharacterRestriction'),
        v => v == this.form.password || this.$t('lang.register.confirmPwdRules.passwordConsistency')
      ],
      phoneNumberRules: [
        v => !!v || this.$t('lang.register.phoneNumberRules.required'),
        v => /^1[3456789]\d{9}$/.test(v) || this.$t('lang.register.phoneNumberRules.formatValidation')
      ],
      verificationCodeRules: [
        v => !!v ||  this.$t('lang.register.verificationCodeRules.required'),
      ],
      verificationCodeBtnAble: false,
      verificationCodeBtnlabel: '',
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1
      }
    };
  },
  created() {
    vm = this;
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
    register() {
      console.log(this.$refs.form)
      if(!this.$refs.form.validate()){
        return
      }
      let param = {
        username: this.form.username,
        password: this.form.password,
        phone: this.form.phoneNumber,
        smsCode: this.form.verificationCode
      }
      axios.post(`${apiUrl}register`, qs.stringify(param)).then(res => {
        console.log(res)
        if(res.status == 200) {
          vm.showTips(vm.$t('lang.tips.register.success'), 'success')
          setTimeout(() => {
            vm.toLogin()
          },3000)
          return
        }
        vm.showTips(vm.$t('lang.tips.register.failed'), 'error')

      }).catch(error => {
        console.log(error)
        vm.showTips(vm.$t('lang.tips.register.failed'), 'error')
      })
    },
    //通知提示工具
    showTips (text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if(timeout) {
        this.tips.timeout = timeout
      }else{
        this.tips.timeout = 3000
      }
    },
    //获取验证码
    getVerificationCode() {
      if(!this.form.phoneNumber){
        this.showTips(this.$t('lang.register.phoneNumberRules.required'), 'error')
        return
      }
      if(!/^1[3456789]\d{9}$/.test(this.form.phoneNumber)){
        this.showTips(this.$t('lang.register.phoneNumberRules.formatValidation'), 'error')
        return
      }
      //点击获取验证码后 60s后才能再次获取
      this.verificationCodeBtnAble = true
      let seconds = 60
      this.verificationCodeBtnlabel = `${this.$t('lang.register.resendverificationCode')}(${seconds})`
      let interval = setInterval(() =>{
        seconds -= 1
        if(seconds <= 0){
          vm.verificationCodeBtnAble = false
          clearInterval(interval)
        }
        vm.verificationCodeBtnlabel = `${this.$t('lang.register.resendverificationCode')}(${seconds})`
      }, 1000)

      this.sendSMSCode()
    },
    //发送验证码
    async sendSMSCode() {
      let res = await axios.post(`${apiUrl}sendSMSCode`, qs.stringify({phone: this.form.phoneNumber}));
      if(res.status == 200) {
        this.showTips(this.$t('lang.register.sendSuccess'), 'success')
        return
      }
      this.showTips(this.$t('lang.register.sendFail'), 'error')
    }
  },
};
</script>
<style scoped>
.background-color {
  background: rgb(77, 139, 255);
}
.register-title {
  color: rgb(0, 110, 255);
  font-weight: bold;
  font-size: 24px;
  font-family: "Microsoft YaHei";
}
.login-tip {
  text-align: center;
}
.copyright {
  font-size: 14px;
  font-family: "Microsoft YaHei";
  position: fixed;
  bottom: 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
</style>